:global(.custom-blog-slider) {
  .slick-dots {
    display: none !important;
    bottom: -40px !important;
    justify-content: center !important;
    align-items: center !important;

    @media (max-width: 768px) {
      display: flex !important;
    }

    li {
      margin: 0 10px !important;

      button {
        width: 12px !important;
        height: 12px !important;
        border-radius: 50% !important;
        background-color: #6d96e1 !important;
        border: none !important;

        &:before {
          display: none !important;
        }
      }

      &.slick-active button {
        width: 24px !important;
        height: 12px !important;
        background-color: #0B52D5 !important; // your override color
        border-radius: 12px !important;
        margin: 0;
      }
    }
  }

  .slick-slide {
    padding: 0 30px;
    box-sizing: border-box;
    height: 100% !important;

    & > div {
      height: 100% !important;
      width: 100%;
    }
  }

  .slick-list {
    margin: 0 -20px;
    overflow: hidden;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-arrow {
    display: none !important;
  }
}
