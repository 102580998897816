.bubbles-wrapper {
  text-align: center;
  padding: 60px 20px 60px 20px ;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px; 
  width: 90%;
  margin: auto;
}

.image-container img {
  width: 450px;
  height:280px
}

/* Ensures equal-sized bubbles and prevents overlap */
.bubbles {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 30px; 
  width: 100%;
  max-width: 360px;
  justify-content: center;
}

/* Uniform size for all bubbles */
.bubble {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); 
  transition: transform 0.2s ease-in-out; 

  &:hover {
    transform: scale(1.1); 
  }
}

/* Ensure text does not overflow */
.bubble p {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}


