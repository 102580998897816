.custom-blog-slider {
    .slick-dots {
      display: none !important;
      bottom: -40px !important;
      justify-content: center !important;
      align-items: center !important;
  
      @media (max-width: 768px) {
        display: flex !important;
      }
  
      li {
        margin: 0 10px !important;
  
        button {
          width: 12px !important;
          height: 12px !important;
          border-radius: 50% !important;
          background-color: #BBBBBB !important;
          border: none !important;
  
          &::before {
            display: none !important;
          }
        }
  
        &.slick-active button {
          background-color: #0B52D5 !important; // force override
          width: 24px !important;
          height: 12px !important;
          border-radius: 12px !important;
        }
      }
    }
  }
  