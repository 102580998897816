/* Initial state before animation */
.fade-up {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

/* Animation is applied once */
.animate {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
