.bubbles-mobile-wrapper-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    width: 100%;
    padding: 30px 20px 30px 20px ;
    overflow: hidden;
    height: 360px;
  }
  
  .heading-mobile {
    max-width: 90%;
  }
  
  .content-mobile-container-mobile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .image-container-mobile img {
    max-width: 150px;
    height: 100px;
    display: block;
  }
  
  .bubbles-mobile {
    position: absolute;
    width: 100%;
  }
  
  .bubble-mobile {
    position: absolute;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
  }
  
  .bubble-text-mobile {
    font-size: 9px !important;
    font-weight: 600;
    text-align: center;
    color: #fff;
  }
  