.container {
  background: #e0ebff;
  padding: 28px 0px;
  font-family: "Poppins";

  @media (max-width: 600px) {
    padding: 0px;
    /* Set padding to 0 on small devices */
  }

  .subContainer {
    max-width: 80%;
    margin: 0 auto;

    // .part1Title,
    // .part2Item,
    // .part3Grid,
    // .part4Container {
    //   opacity: 0;
    //   transform: translateY(100px);
    //   transition: transform 0.6s ease, opacity 0.6s ease;
    // }
    .part4Container {
      height: 100px;
      /* Set fixed height to 100px */
      margin-top: 36px;
    }

    .part1Title {
      padding-top: 32px;

      color: #212121;
      text-align: center;
      font-family: "Poppins";
      font-size: 24px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0.2px;
    }

    // .part2Item{
    //   display: flex;
    // }
    /* Grid container for the images */
    .part3Row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      /* 4 items per row on all screens */
      gap: 20px;
      /* Equal gap between grid items */

      margin: 0;
      // margin-top: 50px;
      max-width: 100%;

      /* Adjust grid layout for larger screens */
      @media (min-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
        /* Keep 4 items per row on medium screens */
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
        /* 4 items per row on large screens */
      }

      .part3Item {
        display: flex;


      }

      .part3ImageContainer {
        width: 100%;
        max-width: 150px;
        /* Smaller images on small screens */
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        /* Small screens (less than 768px) */
        @media (max-width: 767px) {
          img {
            max-width: 54px;
            /* Set the image size for small screens */
            max-height: 54px;
            /* Set the height for small screens */
          }
        }

        /* Medium screens (768px and above) */
        @media (min-width: 768px) and (max-width: 1023px) {
          img {
            max-width: 54px;
            /* Image size for medium screens */
            max-height: 54px;
          }
        }

        /* Large screens (1024px and above) */
        @media (min-width: 1024px) {
          img {
            max-width: 150px;
            /* Increase image size for large screens */
            max-height: 150px;
          }
        }


        .part3ImageTitle {
          color: #062b6e;
          text-align: center;
          font-family: "Poppins";
          font-size: 16px !important;
          font-style: normal;
          font-weight: 600 !important;
          line-height: normal;
          letter-spacing: 0.2px;
          margin-top: 10px;
        }

        .part3Image {
          width: 100%;
          height: auto;
          border-radius: 8px;
        }


      }
    }

    .part4Title {
      color: #212121;
      text-align: center;
      font-family: "Poppins";
      font-size: 24px !important;
      font-style: normal;
      font-weight: 600 !important;
      line-height: normal;
      letter-spacing: 0.2px;
      // margin-top: 60px;
    }

    .buttonContainer {
      margin-top: 30px;
      text-align: center;

      .part4Button {
        background-color: #062b6e;
        color: #fff;
        padding: 12px 30px;
        border: none;
        font-weight: 600 !important;
        cursor: pointer;
        text-transform: none;
        transition: background-color 0.3s;
        border-radius: 4px;
        background: #34a853;
        font-size: 16px;

        &:hover {
          background: #096d24;
        }
      }
    }

    .animateIn {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.6s ease, opacity 0.6s ease;
      // padding: 32px 0px  ;
      padding: 0px;
      padding-top: 32px;
    }
  }
}